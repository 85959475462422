@font-face {
    font-family: 'Work Sans';
    src: url('assets/fonts/WorkSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
body,
textarea {
    font-family: 'Work Sans', Sans-Serif !important;
    font-weight: normal;
    font-style: normal;
}
body {
    margin: 0;
}

/*Custom Scrollbar*/
/* width */
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/*Remove unnecessary highlights from all types on inputs*/
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    font-family: 'Work Sans';
}

/* To remove counter on input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
